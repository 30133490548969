import { b2cPolicies, b2cPoliciesForPhone, protectedResources } from '@app/core/settings/b2c-config';
import { MsalGuardConfiguration, MsalInterceptorConfiguration, } from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';
import { environment } from "@environments/environment";

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export const loggerCallback = (logLevel: LogLevel, message: string): void => {
  console.log(message);
}

export const msalInstanceFactory = (): IPublicClientApplication => {
  const isClientApproveTimesheets = window.location.pathname?.startsWith('/client-approve-timesheets');

  return new PublicClientApplication({
    auth: {
      clientId: b2cPolicies.clientId, // This is the ONLY mandatory field that you need to supply.
      authority: isClientApproveTimesheets ? b2cPoliciesForPhone.authorities.signUpSignIn.authority : b2cPolicies.authorities.signUpSignIn.authority, // Defaults to "https://login.microsoftonline.com/common"
      knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
      redirectUri: b2cPolicies.redirectUri, // Points to window.location.origin. You must register this URI on Azure portal/App Registration.
      postLogoutRedirectUri: isClientApproveTimesheets ? b2cPoliciesForPhone.postLogoutRedirectUri : b2cPolicies.postLogoutRedirectUri, // Indicates the page to navigate after logout.
      navigateToLoginRequestUrl: b2cPolicies.navigateToLoginRequestUrl, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });
}

export const msalInterceptorConfigFactory = (): MsalInterceptorConfiguration => {
  return {
    interactionType: getInteractionType(),
    protectedResourceMap: protectedResources,
  };
}

export const { neoLinkBaseUrl } = environment

export const msalGuardConfigFactory = (): MsalGuardConfiguration => {
  return {
    interactionType: getInteractionType(),
    authRequest: (_authService, state): any => {
      return {
        authority: state.url?.startsWith('/client-approve-timesheets')
          ? b2cPoliciesForPhone.authorities.signUpSignIn.authority
          : b2cPolicies.authorities.signUpSignIn.authority
      }
    },
    loginFailedRoute: 'login-failed',
  };
}

export const getInteractionType = (): any => {
  // Bypass Interaction type for unit test.
  return environment.isTest ? InteractionType.None : InteractionType.Redirect;
}

export const amplitudeApiKey = (): string => {
  return environment.amplitudeApiKey;
}

export const amplitudeServerZone = (): string => {
  return environment.amplitudeApiKeyServerZone ?? 'EU';
}

export const getMsBaseUrl = (): string => {
  return environment.msBaseUrl ?? '';
}
