import { LayoutModule } from '@angular/cdk/layout';
import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from '@app/app.component';
import { appRoutingModule } from '@app/app.routing';
import { CoreModule } from '@app/core/core.module';
import {
  msalGuardConfigFactory,
  msalInstanceFactory,
  msalInterceptorConfigFactory
} from '@app/core/settings/app.config';
import { FailedComponent } from '@app/pages/failed/failed.component';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalRedirectComponent,
  MsalService
} from '@azure/msal-angular';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { NEO_TOOLTIP_SHOW_DELAY } from './core/models/ui.model';
import { ErrorComponent } from './pages/pages/error/error.component';
import { LoaderService } from './services/loader.service';
import { SharedModule } from './shared/shared.module';
import { AmplitudeService } from './services/amplitude.service';

@NgModule({
  declarations: [AppComponent, FailedComponent, ErrorComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    appRoutingModule,
    MsalModule,
    CoreModule,
    HttpClientModule,
    LayoutModule,
    PowerBIEmbedModule,
    SharedModule,
    MatSnackBarModule,
    MatIconModule,
    MatProgressBarModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: msalInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: msalGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: msalInterceptorConfigFactory,
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: navigator.language
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MAT_MOMENT_DATE_FORMATS
    },
    {
      provide: ErrorHandler,
      useClass: ApplicationinsightsAngularpluginErrorService,
    },
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: { showDelay: NEO_TOOLTIP_SHOW_DELAY } as MatTooltipDefaultOptions
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    LoaderService,
    AmplitudeService,
    DatePipe
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule { }
