import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatSortModule } from '@angular/material/sort';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AlertComponent } from './components/alert/alert.component';
import { NeoButtonComponent } from './components/button/button.component';
import { NeoChipComponent } from './components/chip/chip.component';
import { ConfirmDialogComponent } from './components/confirm-dialogue/confirm-dialog.component';
import { HeaderComponent } from './components/header/header.component';
import { NeoCardListComponent } from './components/neo-card-list/neo-card-list.component';
import { NeoCardComponent } from './components/neo-card/neo-card.component';
import { NeoFlyoutFilterComponent } from './components/neo-flyout-filter/neo-flyout-filter.component';
import { NeoInitialsAvatarComponent } from './components/neo-initials-avatar/neo-initials-avatar.component';
import { NeoMediaViewerComponent } from './components/neo-media-viewer/neo-media-viewer.component';
import { NeoSignaturePadComponent } from './components/neo-signature-pad/neo-signature-pad.component';
import { NeoTableComponent } from './components/neo-table/neo-table.component';
import { NeoToggleButtonComponent } from './components/neo-toggle-button/neo-toggle-button.component';
import { NeoTriStateToggleComponent } from './components/neo-tri-state-toggle/neo-tri-state-toggle.component';
import { NeoUploadPhotoComponent } from './components/neo-upload-photo/neo-upload-photo.component';
import { AutoScrollDirective } from './custom-directives/auto-scroll.directive';
import { EllipsifyDirective } from './custom-directives/ellipsify.directive';
import { ImageDragDirective } from './custom-directives/image-drag.directive';
import { SearchFilterPipe } from './custom-pipes/custom-search.pipe';
import { FindPipe } from './custom-pipes/find.pipe';
import { HolidayTypesPipe } from './custom-pipes/holiday-types.pipe';
import { NeoLongDateAndTimePipe } from './custom-pipes/neo-long-date-and-time.pipe';
import { NeoLongDatePipe } from './custom-pipes/neo-long-date.pipe';
import { NeoShortDateAndTimePipe } from './custom-pipes/neo-short-date-and-time.pipe';
import { NeoShortDatePipe } from './custom-pipes/neo-short-date.pipe';
import { NeoTimeWithSecondsPipe } from './custom-pipes/neo-time-with-seconds.pipe';
import { NeoTimeWithoutSecondsPipe } from './custom-pipes/neo-time-without-seconds.pipe';
import { RemoveCommaPipe } from './custom-pipes/remove-comma.pipe';
import { SecureImagePipe } from './custom-pipes/secureImage.pipe';
import { SecurePdfPipe } from './custom-pipes/securePdf.pipe';
import { TimesheetFieldValuePipe } from './custom-pipes/timesheet-field-value.pipe';

@NgModule({
  declarations: [
    SecureImagePipe,
    SecurePdfPipe,
    RemoveCommaPipe,
    SearchFilterPipe,
    NeoLongDateAndTimePipe,
    NeoShortDateAndTimePipe,
    NeoLongDatePipe,
    FindPipe,
    EllipsifyDirective,
    AutoScrollDirective,
    HeaderComponent,
    NeoButtonComponent,
    NeoCardComponent,
    AlertComponent,
    NeoTableComponent,
    NeoCardListComponent,
    NeoToggleButtonComponent,
    NeoFlyoutFilterComponent,
    NeoSignaturePadComponent,
    NeoTriStateToggleComponent,
    NeoMediaViewerComponent,
    NeoShortDatePipe,
    NeoTimeWithSecondsPipe,
    NeoTimeWithoutSecondsPipe,
    NeoUploadPhotoComponent,
    ImageDragDirective,
    TimesheetFieldValuePipe,
    NeoChipComponent,
    HolidayTypesPipe,
    ConfirmDialogComponent,
    NeoInitialsAvatarComponent,
  ],
  imports: [
    CommonModule,
    A11yModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatFormFieldModule,
    FlexLayoutModule,
    MatButtonToggleModule,
    MatMenuModule,
    MatExpansionModule,
    MatListModule,
    FormsModule,
    MatCardModule,
    MatToolbarModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatChipsModule,
    MatSlideToggleModule,
  ],
  exports: [
    RemoveCommaPipe,
    SearchFilterPipe,
    NeoTableComponent,
    FlexLayoutModule,
    NeoToggleButtonComponent,
    NeoFlyoutFilterComponent,
    EllipsifyDirective,
    AutoScrollDirective,
    HeaderComponent,
    NeoTriStateToggleComponent,
    NeoSignaturePadComponent,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatDatepickerModule,
    NeoCardComponent,
    SecureImagePipe,
    SecurePdfPipe,
    FindPipe,
    TimesheetFieldValuePipe,
    NeoCardListComponent,
    NeoLongDateAndTimePipe,
    NeoShortDateAndTimePipe,
    NeoLongDatePipe,
    NeoShortDatePipe,
    NeoTimeWithSecondsPipe,
    NeoTimeWithoutSecondsPipe,
    NeoButtonComponent,
    NeoChipComponent,
    HolidayTypesPipe,
    ConfirmDialogComponent,
    NeoInitialsAvatarComponent,
    ImageDragDirective,
  ],
  providers: [
    SecureImagePipe,
    SecurePdfPipe,
    NeoLongDateAndTimePipe,
    NeoShortDateAndTimePipe,
    NeoLongDatePipe,
    NeoShortDatePipe,
    NeoTimeWithSecondsPipe,
    NeoTimeWithoutSecondsPipe,
    HolidayTypesPipe,
  ],
})
export class SharedModule { }
